import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Moment from 'react-moment';
import { Button, Modal, Spinner, Accordion } from "react-bootstrap";
import { connect, useDispatch, useSelector } from 'react-redux';

import { getAskQuestionData, showingLoaderStudentAction, getSearTextFailureAction, getSearTextSuccessAction, getClickQuestionNoAction } from '../../store/actions/StudentAction';

import mascot_male from '../../images/clv_mascot_male.png';

let elibraryTitle;
let eCategory;
let elibraryItem;
let elibrarySubjectName
let elibrarySelectId

const Footer = (props) => {

	var d = new Date();
	const dispatch = useDispatch();
	const history = useHistory();

	const [askInputModal, setAskInputModal] = useState(false)
	const [activeAccordionHeaderBg, setActiveAccordionHeaderBg] = useState(0);
	const [activeAccordion, setActiveAccordion] = useState('');

	const [question, setQuestion] = useState({
		ques: ''
	})

	let errorsObj = {
		ques: '',
	};

	const [errors, setErrors] = useState(errorsObj);
	const [fname, setFname] = useState('')

	const searchText = useSelector(state => state.student.searchText);
	const clickQuestion = useSelector(state => state.student.clickQuestion);
	const showLoading = useSelector(state => state.student.showLoading);
	const searchTextCallOrNot = useSelector(state => state.student.searchTextCallOrNot);
	const isCallIcon = useSelector(state => state.elibrary.isCallIcon);
	const demoUserOrNot = useSelector(state => state.auth.user_id);
	const is_subscribe_e_library = useSelector(state => state.auth.is_subscribe_e_library);
	const ElibraryScholasticCategory = useSelector((state) => state.elibrary.ElibraryScholasticCategory);

	let EbiraryLocalstorageDetails = localStorage.getItem('localstrageElibraryDetails');
	// let ExamIdFromLocalStorage = JSON.parse(getExamId);
	if (EbiraryLocalstorageDetails != "" && EbiraryLocalstorageDetails != null && EbiraryLocalstorageDetails != undefined) {

		elibraryTitle = JSON.parse(EbiraryLocalstorageDetails).title;
		eCategory = JSON.parse(EbiraryLocalstorageDetails).category;
		elibraryItem = JSON.parse(EbiraryLocalstorageDetails).item;
		elibrarySubjectName = JSON.parse(EbiraryLocalstorageDetails).subjectName;
		elibrarySelectId = JSON.parse(EbiraryLocalstorageDetails).selectId;
	}


	useEffect(() => {
		let fname;
		let getData = localStorage.getItem('userDetails');
		
		if (getData != null && getData != undefined && getData != '') {
			let fname = JSON.parse(getData).fname;
			setFname(fname);
		}


		// console.log("*****", ElibraryScholasticCategory)
		// console.log("**ElibraryScholasticCategory[3]***", ElibraryScholasticCategory[3])
		return () => {
			dispatch(getClickQuestionNoAction(0));
		};
	}, [ElibraryScholasticCategory]);


	const openQuestoonModal = () => {
		setQuestion(question => ({
			...question,
			ques: '',
		}))
		setActiveAccordion('')
		// setQuestion({ ques: '' })

		setErrors({ ques: '' })
		dispatch(getSearTextFailureAction([]))
		setAskInputModal(true)
	}

	const setdata = (e) => {
		const { name, value } = e.target
		setQuestion((preValue) => {
			return {
				...preValue,
				[name]: value
			};
		})
	}

	const closeAskInputModal = () => {
		setAskInputModal(false);
		setQuestion(question => ({
			...question,
			ques: '',
		}))
		setActiveAccordion('')
		// setQuestion({ ques: '' })
		setErrors({ ques: '' })
		dispatch(getSearTextFailureAction([]))
	}

	const handleKeypress = e => {
		//it triggers by pressing the enter key
		if (e.keyCode === 13) {
			questionSubmit();
		}
	};

	const questionSubmit = (e) => {
		e.preventDefault();
		const { ques } = question;

		const re = /^[A-Za-z]+$/;
		const onlyNumber = /^[0-9]+$/;

		let error = false;
		const errorObj = { ...errorsObj };

		if (ques == '') {

			errorObj.ques = 'Kindly input your question';
			error = true;
		} else if (ques.length < 3) {
			errorObj.ques = 'Kindly valid input your question';
			error = true;
		}

		setErrors(errorObj);
		if (error) {
			return;
		} else {
			dispatch(getSearTextSuccessAction([]));
			setActiveAccordion('')
			dispatch(showingLoaderStudentAction(true))
			// dispatch(getAskQuestionData(ques, ElibraryScholasticCategory != undefined && ElibraryScholasticCategory != "" ? ElibraryScholasticCategory[3] : "", props.history))
			dispatch(getAskQuestionData(ques, ElibraryScholasticCategory != undefined && ElibraryScholasticCategory != "" ? ElibraryScholasticCategory[3] : elibrarySelectId, props.history))
		}
	}

	const selectAccordianIndex = (d, i) => {
		setActiveAccordion('yes')
		setQuestion(question => ({
			...question,
			ques: '',
		}))

		dispatch(getSearTextSuccessAction([d]));
		dispatch(getClickQuestionNoAction(i + 1));
	}

	const goToSubscription = () => {
		setAskInputModal(false);
		history.push({ pathname: "/subscription" })
	}

	return (
		<>

			<div className="footer">
				<div className="copyright">
					<p>Copyright © Designed &amp; Developed by{" "}
						<a href="http://crestest.com/" target="_blank" rel="noreferrer">
							{/* <Link to="https://clvdev.in/"> */}
							Crestest Learning Ventures LLP</a >
						{/* </Link> */}
						{" "} {<Moment format="YYYY" withTitle>{d}</Moment>}
					</p >
				</div >
				{isCallIcon == 1 && demoUserOrNot != 0 ?
					<div className="cursorPointer askQuestion_container" onClick={() => openQuestoonModal()}>
						<div className="askQuestion" >
							{/* <i class="fas fa-headset"></i> */}
							<img width="80" src={mascot_male} alt="" />
						</div>

						<div className="ask_me">Ask Me!</div>
					</div>
					: null}
			</div >
			<Modal className="fade bd-example-modal-lg" show={askInputModal} size="lg" onHide={() => setAskInputModal(false)}>

				<Modal.Header closeButton>
					<Modal.Title className="modal_title">{is_subscribe_e_library != null && is_subscribe_e_library != undefined && is_subscribe_e_library != 0 ? "Enter keywords to clear doubt" : `Hello  ${fname}`}</Modal.Title>
				</Modal.Header>
				{is_subscribe_e_library != null && is_subscribe_e_library != undefined && is_subscribe_e_library != 0 ?
					<Modal.Body>
						<div className="col-lg-12">

							<div className="basic-form">

								{/* <form onSubmit={(e) => e.preventDefault()}> */}
								<form onSubmit={questionSubmit} >
									<div className="row">
										<div className="col-sm-12 mb-2">
											<label className="col-sm-6 col-form-label col-form-label-md">
											Type keywords
											</label>
											<input
												// ref={mobileOtpRef}
												type="text"
												className="form-control input_value"
												placeholder="your keywords"
												onChange={setdata}
												value={question.ques}
												name="ques"
												autocomplete="off"
											// maxLength={6}
											/>
											{errors.ques && <div className="text-danger fs-12">{errors.ques}</div>}
										</div>
									</div>
								</form>

								
								{searchText != '' && activeAccordion == '' ?
									<Accordion
										// className="accordion accordion-header-bg"
										className="accordion modal_parent_container"
									// defaultActiveKey = "0"
									>
										{searchText.map((d, i) => (
											<Accordion.Item key={i} eventKey={`${i}`} onClick={() => selectAccordianIndex(d, i)}>

												<Accordion.Header
													className={`accordion-header ${activeAccordionHeaderBg === i ? "" : "collapsed"}`}
												>

													<span className="accordion-header-icon">{i + 1}</span>
													<span className="accordion-header-text"><strong>Question: </strong><div dangerouslySetInnerHTML={{ __html: d.question }}></div></span>
													<span className="accordion-header-indicator"></span>
												</Accordion.Header>
												<Accordion.Collapse eventKey={`${i}`} >
													<>
														<div className="accordion_body"><strong>Answer: </strong><div dangerouslySetInnerHTML={{ __html: d.right_ans }}></div></div>
														<div className="accordion_body"><strong>Reason: </strong><div dangerouslySetInnerHTML={{ __html: d.reason }}></div></div>
													</>
												</Accordion.Collapse>
											</Accordion.Item>
										))}
									</Accordion>
									:
									searchText == '' && searchTextCallOrNot != 0 && !showLoading ?
										<div className="search_details_container">
											<div>No data found</div>
										</div>
										: null}
							</div>
							{activeAccordion == 'yes' ?
								<>
									{/* <div dangerouslySetInnerHTML={{ __html: searchText[0].question }}></div> */}
									<div class="accordion_button collapsed"><span class="accordion-header-text"><strong>Question: </strong><div dangerouslySetInnerHTML={{ __html: searchText[0].question }}></div></span>
										<div className="accordion_body"><strong>Answer: </strong><div dangerouslySetInnerHTML={{ __html: searchText[0].right_ans }}></div></div>
										<div className="accordion_body"><strong>Reason: </strong><div dangerouslySetInnerHTML={{ __html: searchText[0].reason }}></div></div>
									</div>

								</>
								: null
							}
						</div>
						{showLoading ?
							<div className="spinner_parent_container">
								<div className="spinner-container-footer">
									<div className="spinner-view">
										<Spinner
											as="span"
											animation="border"
											//size="sm"
											role="status"
											aria-hidden="true"
										/>
									</div>
								</div>
							</div>

							: null}
					</Modal.Body>
					: <Modal.Body>
						<div className="col-lg-12">
							To access “Ask Me” for framing question and getting the solution, please subscribe to get your own course materials.
						</div>

					</Modal.Body>
				}
				<Modal.Footer>
					<div className="modal_footer_container_info">
						<div className="modal_footer_right_container">

							<Button
								variant="danger text-center modal_footer_button"
								onClick={() => closeAskInputModal()}
							>
								Close
							</Button>
							{is_subscribe_e_library != null && is_subscribe_e_library != undefined && is_subscribe_e_library != 0 ?
								<Button
									variant="warning text-center modal_footer_button"
									onClick={questionSubmit}
									disabled={showLoading ? true : false}
									autoFocus={true}
								>
									Submit
								</Button>
								:
								<Button
									variant="warning text-center modal_footer_button"
									onClick={goToSubscription}
									disabled={showLoading ? true : false}
								>
									Subscribe Now
								</Button>}

						</div>
					</div>

				</Modal.Footer>
			</Modal>
			<div><ToastContainer /></div>
		</>
	);
};

export default Footer;
